import React from 'react';
import Sidebar from '../components/Sidebar';
import styled from 'styled-components';
import t from '../lang';
import config from '../config';

const ContentImage = styled.img`
  margin-bottom: 1rem;
`;

const PhilosophyPage = (props) => {

  const data = props.data || {};

  if (Object.keys(data).length > 0 && data.philosophy) {
    return (
      <div>
        <Sidebar page={props.location.pathname.split('/')[2]}>
          <ContentImage src={`${config.adminBase}/${data.philosophy.image.path}`} alt=""/>
          <div dangerouslySetInnerHTML={{__html: t(data.philosophy, 'text')}}></div>
          <br/>
          <ContentImage src={`${config.adminBase}/${data.service.image.path}`} alt=""/>
          <div dangerouslySetInnerHTML={{__html: t(data.service, 'text')}}></div>
          <br/>
          <ContentImage src={`${config.adminBase}/${data.structure.image.path}`} alt=""/>
          <div dangerouslySetInnerHTML={{__html: t(data.structure, 'text')}}></div>
          <br/>
          <ContentImage src={`${config.adminBase}/${data.equipment.image.path}`} alt=""/>
          <div dangerouslySetInnerHTML={{__html: t(data.equipment, 'text')}}></div>
        </Sidebar>
      </div>
    );
  }

  return null;
};

export default PhilosophyPage;